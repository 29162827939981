import {Button, Col} from "react-bootstrap";
import RoomsList from "../Rooms/RoomsList";
import {useDispatch, useSelector} from "react-redux";
import {uiActions} from "../../store/ui-slice";
import useCheckPermission from "../../hooks/use-check-permission";
import DropdownFirstTitleMenu from "./DropdownFirstTitleMenu";


const RoomMenu = () => {
    const dispatch = useDispatch();
    const createMessage = useCheckPermission('createMessage');
    const activeContainer = useSelector(state => state.ui.activeRoomContainer);
    const isActiveSuperAdminMode = useSelector(state => state.ui.activeSuperAdminMode);
    const handleShowNewThreadModal = () => {
        dispatch(uiActions.toggleNewThreadTypeModal());
    }
    const displayOption = activeContainer !== true ? 'display-option' : '';
   return (
       <Col className={`${'col-12 col-sm-12 col-md-4 col-lg-3  first-part overflow-auto pt-0'} ${displayOption}`}>
           <div className="row pt-3 pb-3 sticky-top menu-title-box">
               <div className="col-12">
                   <div className="row align-items-center justify-content-between">
                       <div className="col-7 col-md-6 col-lg-7 pr-0">
                           <span className='sm-title'>{isActiveSuperAdminMode ? 'Super Admin': 'Secure Messaging'}</span>
                       </div>

                       <div className="col-5 col-md-6 col-lg-5 pl-0 d-flex justify-content-end">
                           <DropdownFirstTitleMenu/>
                           {createMessage && <Button className='btn-primary btn-cf' onClick={handleShowNewThreadModal}>Add New</Button> }
                       </div>
                   </div>
               </div>
           </div>
           <RoomsList/>
       </Col>
   );
}

export default RoomMenu;

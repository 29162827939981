import {Col} from "react-bootstrap";
import messageImage from '../../msg.svg';
import MessageContainerTitle from "../Messages/MessageContainerTitle";
import MessagesList from "../Messages/MessagesList";
import MessageForm from "../Messages/MessageForm";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef} from "react";
import {
    addMessage,
    fetchRoomMessagesData,
    newMessageReaction,
    updateMessageReactions,
    unsetMessageReaction,
    updateSmsDelivery
} from "../../store/message-actions";
import echo from "../../util/pusher-config";
import {uiActions} from "../../store/ui-slice";
import {updateRoomLastMessageDateTime} from "../../store/room-actions";
import useOnlineStatus from "../../hooks/use-online-status";


const MessageContainer = () => {
    const dispatch = useDispatch();
    const activeContainer = useSelector(state => state.ui.activeRoomContainer);
    const displayOption = activeContainer === true ? 'display-option' : '';
    const isRoomParticipant = useSelector(state => state.room.roomParticipant);


    const activeRoomUuid = useSelector(state => state.room.activeRoomIndex);

    const {checkOnlineStatus} = useOnlineStatus();

    const handleShowNewThreadModal = () => {
        dispatch(uiActions.toggleNewThreadTypeModal());
    }
    // const [currentPage] = useState(1);
    // const [perPage] = useState(10);
    const loadNextPage = useRef(0);

    useEffect(() => {
        if (activeRoomUuid !== null) {
            checkOnlineStatus().then((resp) => {
                dispatch(uiActions.toggleIsOnline(resp));
                if (resp) {
                    dispatch(fetchRoomMessagesData(activeRoomUuid));
                }
            });
        }

    }, [dispatch, activeRoomUuid, checkOnlineStatus]);

    useEffect(() => {
        if (activeRoomUuid !== null) {
            echo.private(`rooms.${activeRoomUuid}`)
                .listen('.new_message', (message) => {
                    dispatch(addMessage(message));
                    dispatch(updateRoomLastMessageDateTime(activeRoomUuid, message.created_at));
                }).listen('.update_message_reaction', (message) => {
                dispatch(updateMessageReactions(message));
            }).listen('.sms_delivery_update', (delivery) => {
                dispatch(updateSmsDelivery(delivery))
            });
        }
        return () => {
            if (activeRoomUuid === null) {
                return
            }
            echo.leave(`rooms.${activeRoomUuid}`)
        }
    }, [dispatch, activeRoomUuid]);

    if (activeRoomUuid === null) {
        return (
            <Col
                className={`${'col-12 col-sm-12 col-md-8 col-lg-9 m-0 p-0 second-part position-relative'} ${displayOption}`}>
                <Col
                    className="col-12 col-sm-12 col-md-8 col-lg-9 flex-row d-flex room-box-title border-bottom-0 justify-content-between align-items-center">
                    <i className="bi bi-chevron-left d-md-none d-lg-none"></i>
                    <div className="p-2 room-title">
                    </div>

                </Col>
                <div
                    className="overflow-auto d-flex flex-column justify-content-center room-empty-box-container mt-message-40  align-items-center">
                    <img src={messageImage} alt="" max-width='400px'/>
                    <div className="d-flex flex-column align-items-center">
                        <h3><b>Get Started with Secure Messaging</b></h3>
                        <Col
                            className=" d-flex flex-column col-12 col-sm-12 col-md-8 align-items-center text-align-center">
                            <p>Secure Message Center allows you to send private messages to single people or groups of
                                people. Before using please read and understand our Terms and Conditions.</p>
                            <button className='btn btn-primary' onClick={handleShowNewThreadModal}>Add New</button>

                        </Col>
                    </div>
                </div>
            </Col>
        );
    }
    return (
        <Col
            className={`${'d-flex flex-column col-12 col-sm-12 col-md-8 col-lg-9 m-0 p-0 second-part position-relative'} ${displayOption}`}>
            <div ref={loadNextPage} className='load-next-page'></div>
            <MessageContainerTitle/>
            <MessagesList/>
            {isRoomParticipant && <MessageForm id={activeRoomUuid}/>}
        </Col>
    );
}

export default MessageContainer;

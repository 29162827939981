import {roomActions} from "./room-slice";
import axiosInstance from "../util/axios-config";
import {authActions} from "./auth-slice";
import {uiActions} from "./ui-slice";

export const fetchRoomData = (mode = false) => {
    let uri = '/rooms/list';
    if(mode === true) {
        uri = '/rooms/all_list';
    }

    return async (dispatch) => {
        dispatch(roomActions.reset());
        dispatch(uiActions.toggleLoadingRooms(true));
        await axiosInstance.get(uri).then((response) => {
            const roomData = response.data;
            dispatch(roomActions.setRoomList(roomData));
            dispatch(uiActions.toggleLoadingRooms(false));

            if(roomData.length === 0){
                return
            }

            const lastActiveRoomFromStorage = getLastActiveRoomFromStoarage();

            const room = lastActiveRoomFromStorage ?? roomData[0] ?? null;

            const forActiveRoomData = {
                ...room,
                isRoomParticipant: room.room_participant
            }

            dispatch(roomActions.setActiveRoom(forActiveRoomData))
            dispatch(uiActions.toggleActiveRoomContainer());
        }).catch((error) => {
            dispatch(uiActions.toggleLoadingRooms(false));
            if (error.response !== undefined && error.response.status === 401) {
                dispatch(authActions.logout());
            }
        });
    };
};

const getLastActiveRoomFromStoarage = function(){
    const roomData = localStorage.getItem('last_active_room')

    if(roomData === null){
        return null;
    }

    const roomDataObject = JSON.parse(roomData);

    if(roomDataObject.length === 0){
        return null;
    }

    return roomDataObject;
}
export const fetchPreDefinedRoomList = async (params = '', tag = '', instrument = '', section = '', group_type = '') => {
    let searchParam = '';
    if (params.trim() !== '') {
        searchParam = searchParam + '&filter[name]=' + params.trim();
    }
    if (tag.trim() !== '' && tag.trim() !== 'Select Program' ) {
        searchParam = searchParam + '&filter[tag]=' + tag.trim();
    }
    if (instrument.trim() !== '' && instrument.trim() !== 'Select Instrument') {
        searchParam = searchParam + '&filter[instrument]=' + instrument.trim()+'(instrument)';
    }
    if (section.trim() !== '' && section.trim() !== 'Select Section') {
        searchParam = searchParam + '&filter[section]=' + section.trim();
    }
    if (group_type.trim() !== '') {
        searchParam = searchParam + '&filter[group_type]=' + group_type.trim();
    }
    if(searchParam.length > 0) {
        searchParam = '?filters[all]=all'+searchParam;
    }
    const response = await axiosInstance.get('/rooms/predefined' + searchParam);
    return response.data;
}
export const fetchRoomParticipantsList = async (roomUuid = '', params = '') => {
    let searchParam = '';
    if (params.trim() !== '') {
        searchParam = '?filter[name]=' + params.trim();
    }
    const response = await axiosInstance.post('/rooms/' + roomUuid.trim() + '/participants'+searchParam);
    return response.data;
}

export const createNewRoom = (roomParams, participants) => {
    return async (dispatch) => {
        try {
            const response = await axiosInstance.post('/rooms/create', roomParams);
            const rooms = await response.data;
            const roomsWithParticipants = await axiosInstance.post('/rooms/' + rooms.uuid + '/add-participants', participants);
            dispatch(roomActions.addNewRoom(roomsWithParticipants.data));
        } catch (error) {
            if (error.response !== undefined && error.response.status === 401) {
                dispatch(authActions.logout());
            }
        }
    }
};
export const AddParticipantsToRoom = (roomUuid, participants) => {
    return async (dispatch) => {
        try {
            const roomsWithParticipants = await axiosInstance.post('/rooms/' + roomUuid + '/add-participants', participants);
            dispatch(roomActions.addNewParticipantsToRoom(roomsWithParticipants.data));
        } catch (error) {
            if (error.response !== undefined && error.response.status === 401) {
                dispatch(authActions.logout());
            }
        }
    }
};
export const joinPredefinedGroupRoom = (roomUuid) => {
    return async (dispatch) => {
        try {
            const roomsWithParticipants = await axiosInstance.post('/rooms/' + roomUuid + '/join-predefined');
            dispatch(roomActions.addNewParticipantsToRoom(roomsWithParticipants.data));
        } catch (error) {
            if (error.response !== undefined && error.response.status === 401) {
                dispatch(authActions.logout());
            }
        }
    }
};
export const RemoveParticipantFromRoom = (roomUuid, userId) => {
    return async (dispatch) => {
        try {
            await axiosInstance.delete('/rooms/' + roomUuid + '/delete-participant/' + userId).then(()=> {
                dispatch(roomActions.removeParticipantFromRoom({roomUuid:roomUuid, userId:userId}));
            });
        } catch (error) {
            if (error.response !== undefined && error.response.status === 401) {
                dispatch(authActions.logout());
            }
        }
    }
};
export const RemoveParticipantFromRoomAndRoomFromUI = (roomUuid, userId) => {
    return async (dispatch) => {
        try {
            await axiosInstance.delete('/rooms/' + roomUuid + '/exit-participant/' + userId).then(()=> {
                dispatch(roomActions.removeParticipantFromRoom({roomUuid:roomUuid, userId:userId}));
                dispatch(roomActions.removeRoom(roomUuid));
            });
        } catch (error) {
            if (error.response !== undefined && error.response.status === 401) {
                dispatch(authActions.logout());
            }
        }
    }
};

export const updateRoomLastMessageDateTime = (activeRoomIndex, updatedAt) => {
    return async (dispatch) => {
        dispatch(roomActions.updateRoomLastMessageDateTime({updatedAt, activeRoomIndex}))
    }
}
export const createNewRoomWithPerson = (roomParams) => {
    return async (dispatch) => {
        await axiosInstance.post('/rooms/create-with-person', roomParams).then((response) => {
            const room = response.data;
            dispatch(roomActions.addNewPersonalRoom(room));
        }).catch((error) => {
            if (error.response !== undefined && error.response.status === 401) {
                dispatch(authActions.logout());
            }
        });
    };
};

import {authActions} from "./auth-slice";
import axiosInstance from "../util/axios-config";
import {getAuthToken} from "../util/auth";

export const login = (email, password) => {

  const isTesting = false;

  return async (dispatch) => {
    const authData = {
      password: password,
      email: email,
    };

    if (isTesting) {

      let systemUserId = null;

      await axiosInstance.get(process.env.REACT_APP_API_AUTH_URL + '/get-user-token-test',)
        .then((response) => {
          systemUserId = response.data.system_user_id;
        })

      if (systemUserId) {
        authData.system_user_id = systemUserId;
        authData.authorization_key = process.env.REACT_APP_API_AUTHORIZATION_KEY;
      }

      await axiosInstance.post('/auth/login-by-id', authData).then((response) => {

        const userData = response.data;
        localStorage.setItem('token', userData.token);
        localStorage.setItem('userData', JSON.stringify(userData));
        const expiration = new Date();
        expiration.setHours(expiration.getHours() + 10);
        localStorage.setItem('expiration', expiration.toISOString());
        axiosInstance.defaults.headers.common['Authorization'] = getAuthToken();
        dispatch(authActions.loginSuccess(userData));
      }).catch((error) => {
        dispatch(authActions.loginFail(error.message));
      });

    } else {
      await axiosInstance.post(process.env.REACT_APP_API_AUTH_URL + '/auth/login-by-email', authData).then((response) => {

        const userData = response.data;
        localStorage.setItem('token', userData.token);
        localStorage.setItem('userData', JSON.stringify(userData));
        const expiration = new Date();
        expiration.setHours(expiration.getHours() + 10);
        localStorage.setItem('expiration', expiration.toISOString());
        axiosInstance.defaults.headers.common['Authorization'] = getAuthToken();
        dispatch(authActions.loginSuccess(userData));
      }).catch((error) => {
        dispatch(authActions.loginFail(error.message));
      });
    }
  };
};

export const loginByID = (personID) => {

  return async (dispatch) => {
    const authData = {
      authorization_key: process.env.REACT_APP_API_AUTHORIZATION_KEY,
      system_user_id: personID,
    };

    await axiosInstance.post('/auth/login-by-id', authData).then((response) => {
      const userData = response.data;
      localStorage.setItem('token', userData.token);
      localStorage.setItem('userData', JSON.stringify(userData));
      const expiration = new Date();
      expiration.setHours(expiration.getHours() + 10);
      localStorage.setItem('expiration', expiration.toISOString());
      axiosInstance.defaults.headers.common['Authorization'] = getAuthToken();
      dispatch(authActions.loginSuccess(userData));
    }).catch((error) => {
      dispatch(authActions.loginFail(error.message));
    });
  };
};
export const fetchUserInfo = () => {
  return async (dispatch) => {
    await axiosInstance.get('/user-info').then((response) => {
      const userData = {
        token: getAuthToken(),
        user: response.data
      };
      dispatch(authActions.updateUser(userData));
    }).catch((error) => {
      if (error.response !== undefined && error.response.status === 401) {
        dispatch(authActions.logout());
      }
    });
  }
}
export const setUserAgreement = () => {
  return async (dispatch) => {
    await axiosInstance.post('/user/agree-privacy-policy').then((response) => {
      const userData = {
        token: getAuthToken(),
        user: response.data
      };
      dispatch(authActions.updateUser(userData));
    }).catch((error) => {
      if (error.response !== undefined && error.response.status === 401) {
        dispatch(authActions.logout());
      }
    });
  }
}
export const setUserContactConfirmation = (data = {phone: '', email: ''}) => {
  return async (dispatch) => {

    await axiosInstance.post('/user/confirm-contacts', data).then((response) => {
        const userData = {
        token: getAuthToken(),
        user: response.data
      };

      dispatch(authActions.updateUser(userData));
    }).catch((error) => {
      if (error.response !== undefined && error.response.status === 401) {
        dispatch(authActions.logout());
      }
    });
  }
}

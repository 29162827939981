import {redirect} from 'react-router-dom';

export function getTokenDuration() {
  const storedExpirationDate = localStorage.getItem('expiration');
  const expirationDate = new Date(storedExpirationDate);
  const now = new Date();
  return expirationDate.getTime() - now.getTime();
}

export function getAuthToken() {
  const token = localStorage.getItem('token');

  if (!token) {
    return null;
  }

  const tokenDuration = getTokenDuration();

  if (tokenDuration < 0) {
    return 'EXPIRED';
  }

  return 'Bearer ' + token;
}

export function tokenLoader() {
  return getAuthToken();
}

export async function checkAuthLoader() {
  const token = getAuthToken();
  if (!token || token === 'EXPIRED') {
    localStorage.removeItem('token');
    return redirect('/auth');
  }
  return token;
}

export function checkIfLoggedLoader() {
  const token = getAuthToken();
  if (token && token !=='EXPIRED') {
    return redirect('/chat');
  }
  localStorage.removeItem('token');
  return null;
}

import {Navigate} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {authActions} from "../store/auth-slice";
import {uiActions} from "../store/ui-slice";
import {roomActions} from "../store/room-slice";
import {messageActions} from "../store/message-slice";
import {groupActions} from "../store/group-slice";
import {permissionsActions} from "../store/permissions-slice";


const Logout = () => {
  const dispatch = useDispatch();
  localStorage.removeItem('token');
  localStorage.removeItem('expiration');
  dispatch(authActions.logout());
  dispatch(uiActions.reset());
  dispatch(roomActions.reset());
  dispatch(messageActions.reset());
  dispatch(groupActions.resetGroup());
  dispatch(permissionsActions.reset());
  return <Navigate to='/auth' replace='/logout'/>

}

export function action() {
  return <Navigate to='/logout' replace={true}/>
}

export default Logout;

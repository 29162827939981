import React from 'react';
import { Dropdown } from 'react-bootstrap';
import useCheckPermission from "../../hooks/use-check-permission";
import {useDispatch, useSelector} from "react-redux";
import {uiActions} from "../../store/ui-slice";
import {RemoveParticipantFromRoomAndRoomFromUI} from "../../store/room-actions";

const DropdownTitleMenu = () => {
    const dispatch = useDispatch();
    const silenceNotificationsPermission = useCheckPermission('silenceNotifications');
    const viewArchivePermission = useCheckPermission('viewArchive');
    const roomType = useSelector(state => state.room.activeRoomType);
    const userId = useSelector(state => state.auth.user.id);
    const roomUuid = useSelector(state => state.room.activeRoomIndex);
    const isRoomParticipant = useSelector(state => state.room.roomParticipant);

    const exitChatPermission = useCheckPermission('exitChat') && isRoomParticipant;
    const viewGroupMembers = useCheckPermission('viewGroupMembers') && (roomType === 'group' || roomType === 'predefined');
    const manageGroup = useCheckPermission('manageOwnGroups') && (roomType === 'group');

    const handleExitRoomChat = (e) => {
        e.preventDefault();
        dispatch(RemoveParticipantFromRoomAndRoomFromUI(roomUuid, userId));
    }

    const handleToggleEditGroupModal = () => {
        dispatch(uiActions.toggleEditGroupModal());
    }
    const handleToggleViewGroupModal = () => {
        dispatch(uiActions.toggleViewGroupModal());
    }

    return (
        <>
        { (exitChatPermission || silenceNotificationsPermission || viewArchivePermission || manageGroup || viewGroupMembers) &&
            <Dropdown>
                <Dropdown.Toggle as={CustomButton} id="dropdown-basic" drop="start"/>
                <Dropdown.Menu>
                    {exitChatPermission && <Dropdown.Item href="#/action-1" onClick={handleExitRoomChat}>Remove Chat</Dropdown.Item>}
                    {silenceNotificationsPermission && <Dropdown.Item href="#/action-2">Silence Notifications</Dropdown.Item>}
                    {viewArchivePermission && <Dropdown.Item href="#/action-3">View Archive</Dropdown.Item>}
                    {manageGroup && <Dropdown.Item href="#/action-3" onClick={handleToggleEditGroupModal}>Edit Group</Dropdown.Item>}
                    {viewGroupMembers && <Dropdown.Item href="#/action-3" onClick={handleToggleViewGroupModal}>View Group Members</Dropdown.Item>}
                </Dropdown.Menu>
            </Dropdown>
        }
        </>
    );
};

const CustomButton = React.forwardRef(({ onClick }, ref) => (
    <button
        ref={ref}
        className="bi bi-three-dots-vertical btn shadow-none"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    ></button>
));

export default DropdownTitleMenu;

import {messageActions} from "./message-slice";
import axiosInstance from "../util/axios-config";
import {authActions} from "./auth-slice";
// import {action as logoutAction} from "../pages/Logout";
import {roomActions} from "./room-slice";
import {uiActions} from "./ui-slice";

export const fetchRoomMessagesData = (uuid = '', currentPage, perPage = 20) => {
  return async (dispatch) => {
    dispatch(uiActions.toggleLoadingMessages(true));
    await axiosInstance.get('/rooms/' + uuid + '/messages', {
        params: {
          page: currentPage,
          perPage: perPage
        }
      }
    ).then((response) => {

      const roomMessageData = response.data;
      const paginatorMeta = response.data.meta

      dispatch(messageActions.setPaginator(paginatorMeta))
      dispatch(messageActions.messageSuccess(roomMessageData.data));
      dispatch(uiActions.toggleLoadingMessages(false));
    }).catch((error) => {
      if (error.response !== undefined && error.response.status === 401) {
        dispatch(uiActions.toggleLoadingMessages(false));
        dispatch(authActions.logout());
      }
    });
  };
};

export const addMessage = (message) => {
  return async (dispatch) => {
    const userData = JSON.parse(localStorage.getItem('userData'));

    const hasAttachments = message.hasOwnProperty('attachments') && message.attachments.length > 0

    if (message.author.id !== userData.user.id) {
      message.is_own = false;
      message.is_read = false;
    }

    if (message.is_own && !hasAttachments) {
      dispatch(messageActions.messageDelivered(message))
    } else {
      dispatch(messageActions.addMessage(message));
      playNewMessageSound();
    }

  };
};

export const newMessageReaction = (data) => {
  return async (dispatch) => {
    dispatch(messageActions.newMessageReaction(data))
  };
};
export const updateMessageReactions = (data) => {
    return async (dispatch) => {
        dispatch(messageActions.updateMessageReactions(data))
    };
};

export const unsetMessageReaction = (data) => {
  return async (dispatch) => {
    dispatch(messageActions.unsendMessageReaction(data))
  };
};
export const updateSmsDelivery = (data) => {
  return async (dispatch) => {
    dispatch(messageActions.updateSmsDelivery(data))
  };
};

export const playNewMessageSound = () => {
  const newMessageAudioElement = document.getElementById('new-message-sound');
  if (newMessageAudioElement !== null) {
    newMessageAudioElement.currentTime = 0;
    newMessageAudioElement.play();
  }
};

export const sendMessageReaction = (payload) => {
  return async (dispatch) => {
    const messageId = payload.message_id;
      try {
          await axiosInstance.post(
              `/messages/${messageId}/send-reaction`,
              {
                  reaction_markdown: payload.reaction_markdown
              }
          );
      } catch (error) {
      }
  }
}

const sendAttachmentMessage = async (message, dispatch) => {

  dispatch(messageActions.setSendAttachmentProgress(0));
  const onUploadProgress = (progressEvent) => {
    const {loaded, total} = progressEvent;
    let percent = Math.floor((loaded * 100) / total);
    if (percent <= 100) {
      dispatch(messageActions.setSendAttachmentProgress(percent))
      console.log(`${loaded} bytes of ${total} bytes. ${percent}%`);
    }
  };

  await axiosInstance.post(
    '/messages/send-message',
    message,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress
    }
  ).then((response) => {
    if (response.status !== 200) {
      return
    }

    dispatch(uiActions.toggleSendAttachmentModal())
  }).catch((error) => {
    if (error.response !== undefined && error.response.status === 401) {
      dispatch(authActions.logout());
    } else {
      dispatch(messageActions.failedSending())
    }
  }).finally(() => {
    dispatch(messageActions.setSendAttachmentProgress(0));
  });

}
export const sendNewMessageData = (message) => {
  return async (dispatch) => {
    const randomId = function (length = 6) {
      return Math.random().toString(36).substring(2, length + 2);
    };

    const isFormData = message instanceof FormData

    if (isFormData) {
      await sendAttachmentMessage(message, dispatch)
      return;
    }

    const messageRandomId = randomId(7)

    const messageToPush = {...message}

    messageToPush.parent = null //temporary
    messageToPush.random_id = messageRandomId

    messageToPush.author = JSON.parse(localStorage.getItem('userData')).user ?? {}
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    messageToPush.sent_sms = message.send_sms;
    messageToPush.sent_email = message.send_email;
    messageToPush.reactions = [];
    messageToPush.created_at = today.toISOString();
    messageToPush.is_own = true;

    dispatch(messageActions.addMessage(messageToPush))

    await axiosInstance.post(
      '/messages/send-message',
      message
    ).then((response) => {
      if (response.status !== 200) {
        return
      }

      dispatch(messageActions.sent({random_id:messageRandomId, message: response.data}))
    }).catch((error) => {
      if (error.response !== undefined && error.response.status === 401) {
        dispatch(authActions.logout());
      } else {
        dispatch(messageActions.failedSending())
      }
    }).finally(() => {
      dispatch(messageActions.setSendAttachmentProgress(0));
    });
  };
};

export const readMessage = (messageId) => {
  return async (dispatch) => {
    await axiosInstance.post('/messages/read', {'messages': [messageId]}).then(() => {
      dispatch(messageActions.readMessage(messageId));
      dispatch(roomActions.updateRoomReadMessages());
    });
  }
}
export const reportMessage = (messageId) => {
  return async (dispatch) => {
    await axiosInstance.post('/messages/report', {'message_id': messageId});
  }
}
export const deleteMessage = (messageId) => {
  return async (dispatch) => {
    await axiosInstance.delete('messages/' + messageId + '/delete');
    dispatch(messageActions.deleteMessage(messageId));

  }
}

export const setAutoscroll = (isAutoScroll) => {
  return async (dispatch) => {
    dispatch(messageActions.setAutoScroll(isAutoScroll));
  }
}

import React from 'react';
import {Button, Col, Row} from "react-bootstrap";
import messageImage from "../offlinemode.svg";

const OfflinePage = () => {

    return (
        <>
            <main>
            <Row className='m-0'>
                <Col className={`${'col-12 col-sm-12 col-md-4 col-lg-3  first-part overflow-auto pt-0 height-auto offline-container'} `}>
                    <div className="row pt-3 pb-3 sticky-top menu-title-box">
                        <div className="col-12">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-7 col-md-6 col-lg-7 pr-0">
                                    <span className='sm-title'>Secure Messaging</span>
                                </div>

                                <div className="col-5 col-md-6 col-lg-5 pl-0 d-flex justify-content-end">
                                    <Button className='btn-secondary btn-cf'>Add New</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col className={`${'col-12 col-sm-12 col-md-8 col-lg-9 m-0 p-0 second-part position-relative'}`}>
                    <div
                        className="overflow-auto d-flex flex-column justify-content-center room-empty-box-container  align-items-center">
                        <img src={messageImage} alt="" max-width='500px'/>
                        <div className="d-flex flex-column align-items-center">
                            <h2><b>You Are Offline</b></h2>
                            <Col className=" d-flex flex-column col-12 col-sm-12 col-md-8 align-items-center text-align-center">
                                <p>Blue Devils Secure messaging does not work offline. Please connect to wifi or cell connection.</p>

                            </Col>
                        </div>
                    </div>
                </Col>
            </Row>
            </main>
        </>
    );
}

export default OfflinePage;

import {useDispatch} from "react-redux";
import {roomActions} from "../../store/room-slice";
import {dateFormat} from "../../util/format";
import LastParticipants from "./LastParticipants";
import {uiActions} from "../../store/ui-slice";

const Room = (props) => {

    const dispatch = useDispatch();
    const setActiveRoomHandler = () => {
        dispatch(roomActions.setActiveRoom({
            uuid: props.uuid,
            name: props.name,
            type: props.type,
            kidsCanMessage: props.kidsCanMessage,
            isRoomParticipant: props.isRoomParticipant
        }));
        dispatch(uiActions.toggleActiveRoomContainer());
    }
    const isActiveClass = props.isActive ? 'active' : '';

    let unread_messages = false;
    if (props.unread_messages > 0) {
        unread_messages = true;
    }


    return (
        <div className="row mt-2" onClick={setActiveRoomHandler}>
            <div className="col-12">

                <div className={`${'chat-room d-flex flex-column justify-content-between'} ${isActiveClass}`}>
                    <div className='row'>
                        <div className="col-8">
                            <div className="d-flex flex-column">
                                <p className="room-title">{props.roomTitle}</p>
                                <h6 className='badge-position'>
                                    <span className="badge bg-secondary badge-adjustments">{props.tag}</span>
                                </h6>
                            </div>
                        </div>
                        {unread_messages &&
                            <div className="col-4 text-end">
                                <div className="rounded-circle float-end message-number">
                                    <span className="number">{props.unread_messages}</span>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="row d-flex align-items-end justify-content-between box-space">
                        <LastParticipants
                            last_participant_users={props.last_participant_users}
                            participant_users_count={props.participant_users_count}
                            tag={props.tag !== null ? props.tag : ''}
                        />

                        <div className="col-6 text-right">
                            <span className='text-muted last-message'>{dateFormat(props.updated_at)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Room;

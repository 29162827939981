// MessageForm.js
import React, { useState, useRef } from 'react';
import {sendNewMessageData} from "../../store/message-actions";
import { useDispatch, useSelector } from 'react-redux';
import useCheckPermission from '../../hooks/use-check-permission';
import { Button } from 'react-bootstrap';
import { uiActions } from '../../store/ui-slice';
import useOnlineStatus from '../../hooks/use-online-status';
import RichTextEditor from './RichTextEditor';
import useInput from '../../hooks/use-input'; // Adjust path as necessary
import DOMPurify from 'dompurify';

const MessageForm = (props) => {
    const dispatch = useDispatch();
    const sendAsSmsPermission = useCheckPermission('sendMessageAsSMS');
    let createMessage = useCheckPermission('createMessage');
    const sendAsEmailPermission = useCheckPermission('sendMessageAsEmail');
    const sendAttachmentPermission = useCheckPermission('sendAttachment');

    const kidsCanMessage = useSelector(state => state.room.kidsCanMessage);
    const userRoles = useSelector(state => state.auth.user.roles);
    const { checkOnlineStatus } = useOnlineStatus();
    // const room = useSelector(state => state.room);
    const firstRole = userRoles[0];

    if (firstRole.name === 'band-member') {
        createMessage = kidsCanMessage;
    }

    const enteredMessage = useInput('', (value) => {
        return value.trim() !== '';
    });

    const [sendAsSMS, setSendAsSMS] = useState(true);
    const [sendAsEmail, setSendAsEmail] = useState(true);
    const [sendAsCopy, setSendAsCopy] = useState(false);

    const keyPressHandler = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            sendMessageButtonRef.current.click();
        }
    };

    const handleCheckboxSMS = () => {
        setSendAsSMS(!sendAsSMS);
    };

    const handleCheckboxEmail = () => {
        setSendAsEmail(!sendAsEmail);
    };

    const handleCheckboxCopy = () => {
        setSendAsCopy(!sendAsCopy);
    };

    const openSendAttachmentModal = () => {
        dispatch(uiActions.toggleSendAttachmentModal());
    };

    const sendMessageButtonRef = React.createRef();
    const richTextEditorRef = useRef(null);


    const sendMessageHandler = (event) => {
        event.preventDefault();
        if (!enteredMessage.inputIsValidAndTouched) {
            return;
        }

        const sanitizedMessage = DOMPurify.sanitize(enteredMessage.enteredValue);

        let messageObj = {
            room_uuid: props.id,
            message: sanitizedMessage,
            send_sms: sendAsSmsPermission ? sendAsSMS : false,
            send_email: sendAsEmailPermission ? sendAsEmail : false,
            send_copy: (sendAsEmailPermission || sendAsSmsPermission) ? sendAsCopy : false,
            type: 'text',
            reactions: [],
        };

        checkOnlineStatus().then((resp) => {
            dispatch(uiActions.toggleIsOnline(resp));
            if (resp) {
                dispatch(sendNewMessageData(messageObj));
                enteredMessage.reset();
                setSendAsSMS(!!sendAsSmsPermission);
                setSendAsEmail(!!sendAsEmailPermission);
                setSendAsCopy(false);
                richTextEditorRef.current.resetEditor();
            }
        });
    };

    return (
        <>
            {createMessage &&
                <div className="message-container">
                    <form onSubmit={sendMessageHandler}>
                        <div className="d-flex justify-content-between align-items-center message-box">
                            <div className="col-8 col-sm-10 col-md-10 col-lg-11 align-self-center">
                                <div className="form-group m-0">
                  <textarea
                      className={enteredMessage.inputClasses + ' shadow-none border-0'}
                      id="message-box"
                      rows="3"
                      cols="2"
                      onChange={(event) => enteredMessage.inputHandler(event.target.value)}
                      onBlur={enteredMessage.inputBlurHandler}
                      onKeyPress={keyPressHandler}
                      value={enteredMessage.enteredValue}
                      style={{display:'none'}}
                  />
                                    <RichTextEditor ref={richTextEditorRef} enteredMessage={enteredMessage} />
                                </div>
                            </div>
                            <div className="col-4 col-sm-2 col-md-2 col-lg-1 d-flex align-self-center justify-content-end">
                                {createMessage && <button ref={sendMessageButtonRef} className="btn btn-primary" disabled={!enteredMessage.inputIsValidAndTouched}>Send</button>}
                            </div>
                        </div>
                        <div className="col-12 d-flex p-0 align-items-center">
                            {sendAttachmentPermission &&
                                <Button variant="outline-secondary" size="sm" onClick={openSendAttachmentModal} className="mr-2 mt-1 rounded-circle"><i className="bi bi-plus"></i></Button>
                            }
                            {sendAsEmailPermission &&
                                <div className="form-check text-center mr-4 d-flex align-items-center">
                                    <input type="checkbox" className="form-check-input" id="sendEmail"
                                           checked={sendAsEmail}
                                           onChange={handleCheckboxEmail}
                                    />
                                    <label className="form-check-label" htmlFor="sendEmail">Send as Emails</label>
                                </div>
                            }
                            {sendAsSmsPermission &&
                                <div className="form-check text-center mr-4 d-flex align-items-center">
                                    <input type="checkbox" className="form-check-input" id="sendSms"
                                           checked={sendAsSMS}
                                           onChange={handleCheckboxSMS}
                                    />
                                    <label className="form-check-label" htmlFor="sendSms">Send as SMS</label>
                                </div>
                            }
                            {(sendAsEmailPermission || sendAsSmsPermission) &&
                                <div className="form-check text-center d-flex align-items-center">
                                    <input type="checkbox" className="form-check-input" id="sendCopy"
                                           checked={sendAsCopy}
                                           onChange={handleCheckboxCopy}
                                    />
                                    <label className="form-check-label" htmlFor="sendCopy">Send a Copy</label>
                                </div>
                            }
                        </div>
                    </form>
                </div>
            }
        </>
    );
};

export default MessageForm;

import {dateFormat} from "../../util/format";
import {readMessage} from "../../store/message-actions";
import React, {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import DropdownMessageMenu from "../UI/DropdownMessageMenu";
import {RenderDocument, RenderImage} from "../../util/attachment-renderer";
import {Reactions} from "./Reactions";


const Message = (props) => {
  const dispatch = useDispatch();
  let messageContainerClasses = props.is_own ? 'message-reply-container' : 'message-sent-container';
  let messageOwnerShipClasses = props.is_own ? 'd-flex justify-content-end' : '';
  let messageGapClasses = props.gapMessageFlag ? 'mt-message-5' : 'mt-message-40';
  let newMessageClass = props.is_read ? 'd-none' : '';
  const sentClass = props.is_sent ? 'done-color' : '';
  const deliveredClass = props.is_sent ? 'done-color' : '';
  const showDeliveringInfo = props.is_own ? '' : 'd-none';
  const smsDelivery = props.sms_delivery ?? null;
  const deliveryClass = smsDelivery ? (smsDelivery.messages_count !== smsDelivery.delivered_count ? 'badge-danger' : ' badge-primary ')  : ' badge-primary '

  const attachments = props.attachments ?? [];

  if (props.firstMessage) {
    messageGapClasses = 'mt-message-40';
  }

  const messageRef = useRef(null);

  useEffect(() => {
    let currentMessage = messageRef.current;
    const observer = new IntersectionObserver(([entry]) => {

      if (entry.isIntersecting) {
        if (!props.is_read) {
          setTimeout(() => {
            dispatch(readMessage(props.id))
          }, 500)
        }
      }
    });

    if (currentMessage) {
      observer.observe(currentMessage);
    }

    return () => {
      if (currentMessage) {
        observer.unobserve(currentMessage);
      }
    };
  }, [props.id, props.is_read, dispatch]);

  const renderedAttachments = attachments.map(function (attachment) {
    if (props.type === 'image') {
      return <RenderImage attachment={attachment} key={attachment.id}/>;
    }

    return <RenderDocument attachment={attachment} key={attachment.id}/>;

  });
  return (
    <div className={`${messageGapClasses} ${messageOwnerShipClasses}`} id={`message-${props.id}`} ref={messageRef}>
      <div className={`${'d-flex flex-column'} ${messageContainerClasses}`}>
        <div className="flex-row d-flex  flex-wrap w-100 justify-content-between align-items-center">
          <div className="room-title d-flex   flex-wrap align-items-center">
            <span className='mr-1'>{props.author.name}</span> <a href="/#"
                                                                 className={`badge badge-success sm-title mr-1 ${newMessageClass}`}>New</a>
            {props.sent_sms && <span className={`${'badge mr-1'} ${deliveryClass}`}>sms {smsDelivery && <span>({smsDelivery.delivered_count}/{smsDelivery.messages_count})</span>}</span>}
            {props.sent_email && <span className="badge  badge-primary">email</span>}

          </div>
          <div className=" d-flex justify-content-between align-items-center resp-date">
            <span className='text-muted last-message mr-3'>{dateFormat(props.created_at)}</span>
            <DropdownMessageMenu key={props.id} message_id={props.id} is_own={props.is_own}/>
          </div>
        </div>
        <div className="text-overflow" dangerouslySetInnerHTML={{__html: props.message}}>
        </div>
        {renderedAttachments}
        <div className="d-flex">
          <Reactions message_id={props.id} reactions={props.reactions} key={props.id}/>
          <div className={showDeliveringInfo}>
            <i title='Is Sent' className={`${'bi bi-check'} ${sentClass}`}></i><i title='Is Delivered'
                                                                                  className={`${'bi bi-check is-delivered'} ${deliveredClass}`}></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Message;

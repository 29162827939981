import {createSlice} from "@reduxjs/toolkit";

const initialUiState = {
    newThreadTypeModal: false,
    personSelectionModal: false,
    groupSelectionModal: false,
    preDefinedGroupSelectionModal:false,
    addMembersToNewGroupModal: false,
    createGroupModal: false,
    editGroupModal: false,
    viewGroupModal: false,
    sendAttachmentModal: false,
    editGroupAddToGroupMembersModal: false,
    activeRoomContainer:true,
    notification: null,
    activeSuperAdminMode:false,
    loadingMessages:false,
    loadingRooms:false,
    isOnline: true,
};

const uiSlice = createSlice({
    name: 'ui', initialState: initialUiState,
    reducers: {
        toggle(state) {
            state.isModalShown = !state.isModalShown;
        },
        toggleNewThreadTypeModal: (state) => {
            return {
                ...state,
                newThreadTypeModal: !state.newThreadTypeModal
            }
        },
        togglePersonSelectionModal: (state) => {
            return {
                ...state,
                personSelectionModal: !state.personSelectionModal
            }
        },
        toggleGroupSelectionModal: (state) => {
            return {
                ...state,
                groupSelectionModal: !state.groupSelectionModal
            }
        },
        togglePreDefinedGroupSelectionModal: (state) => {
            return {
                ...state,
                preDefinedGroupSelectionModal: !state.preDefinedGroupSelectionModal
            }
        },
        toggleAddMembersToNewGroupModal: (state) => {
            return {
                ...state,
                addMembersToNewGroupModal: !state.addMembersToNewGroupModal
            }
        },
        toggleCreateGroupModal: (state) => {
            return {
                ...state,
                createGroupModal: !state.createGroupModal
            }
        },
        toggleEditGroupModal: (state) => {
            return {
                ...state,
                editGroupModal: !state.editGroupModal
            }
        },
        toggleSendAttachmentModal: (state) => {
            return {
                ...state,
                sendAttachmentModal: !state.sendAttachmentModal
            }
        },
        toggleViewGroupModal: (state) => {
            return {
                ...state,
                viewGroupModal: !state.viewGroupModal
            }
        },
        toggleEditGroupAddToGroupMembersModal: (state) => {
            return {
                ...state,
                editGroupAddToGroupMembersModal: !state.editGroupAddToGroupMembersModal
            }
        },
        toggleActiveRoomContainer: (state) => {
            return {
                ...state,
                activeRoomContainer: !state.activeRoomContainer
            }
        },
        toggleActiveSuperAdminMode: (state) => {
            return {
                ...state,
                activeSuperAdminMode: !state.activeSuperAdminMode
            }
        },
        toggleLoadingMessages: (state,message) => {
            return {
                ...state,
                loadingMessages: message.payload
            }
        },
        toggleLoadingRooms: (state, loadingRoom) => {
            return {
                ...state,
                loadingRooms: loadingRoom.payload
            }
        },
        toggleIsOnline: (state, isOnline) => {
            return {
                ...state,
                isOnline: isOnline.payload
            }
        },

        reset :(state) => {
            return initialUiState;
        },
        showNotification(state, action) {
            state.notification = {
                status: action.payload.status,
                title: action.payload.title,
                message: action.payload.message
            }
        }
    }
});

export const uiActions = uiSlice.actions;
export default uiSlice;

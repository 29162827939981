import Echo from "laravel-echo";
import axiosInstance from "./axios-config";

require('pusher-js');

let config = {
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        axiosInstance.post('/broadcasting/auth', {
          socket_id: socketId,
          channel_name: channel.name
        }, {
          headers: {
            'Accept': 'application/json',
          }
        })
          .then(response => {
            callback(null, response.data);
          })
          .catch(error => {
            callback(error);
          });
      }
    };
  },
  broadcaster: 'pusher',

  key: 'd18947e809abe023b212',
  //host: process.env.REACT_APP_PUSHER_HOST,
  //port: process.env.REACT_APP_PUSHER_PORT,
  //wsHost: process.env.REACT_APP_PUSHER_WS_HOST,
  // wssHost: process.env.REACT_APP_PUSHER_WSS_HOST,
  //wsPort: process.env.REACT_APP_PUSHER_WS_PORT,
  // wssPort: process.env.REACT_APP_PUSHER_WSS_PORT,
  cluster: "us3",
  forceTLS: false,
  disableStats: true,
  encrypted: false,
  enabledTransports: ['ws', 'wss'],
};
const echo = new Echo(config);

export default echo;

import {Button, Overlay, OverlayTrigger, Popover, Tooltip} from "react-bootstrap";
import {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {messageActions} from "../../store/message-slice";
import {fetchRoomMessagesData, sendMessageReaction} from "../../store/message-actions";

export function Reactions(props) {
    const messageId = props.message_id;
    const currentReactions = props.reactions;
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const dispatch = useDispatch();
    const activeRoomUuid = useSelector(state => state.room.activeRoomIndex);

    const ref = useRef(null)

    const handleClick = (event) => {
        setShow(!show);
        setTarget(event.target);
    };

    const availableReactions = {
        like: '👍', dislike: '👎', drum: '🥁', trumpet: '🎺', red_flag: '🚩', laughing: '🤣',
    };

    const sendReaction = function (event) {
        setShow(!show);
        dispatch(sendMessageReaction({
            message_id: event.target.dataset.message, reaction_markdown: event.target.dataset.value
        }));
    }
    const reactions = () => {
        return Object.entries(availableReactions).map(function ([key, reaction]) {
            return (<span className="reaction-emoji" key={key} title={key} data-value={key} data-message={messageId}
                          onClick={sendReaction}> {reaction}</span>);
        });
    }

    const renderedCurrentReactions = () => {
        return Object.entries(currentReactions).map(function ([reactionName, reactionsData]) {
            const {reaction_count, users} = reactionsData;
            return (<span className="reaction-wrapper" key={reactionName}>
                    <OverlayTrigger
                        delay={{hide: 450, show: 300}}
                        overlay={(props) => (<Tooltip {...props} arrowProps={{className: 'custom-arrow'}}>
                            <div className="w-100 d-flex align-content-center justify-content-center">
                                <div className="tooltip-reaction-icon" >
                                    {availableReactions[reactionName]}
                                </div>
                            </div>
                            {users}
                        </Tooltip>)}
                        placement="top"
                    >
        <span className="reaction-with-count" key={reactionName}>{availableReactions[reactionName]}
            <span className="reaction-count">{reaction_count}</span>
        </span>

                    </OverlayTrigger>
          </span>);
        })
    }

    return (
        <div ref={ref}>
            <Button size="sm" title="Send reaction" onClick={handleClick} variant="outline-secondary"> <i
                className="bi bi-emoji-smile"> </i>
            </Button>
            <Overlay show={show}
                     target={target}
                     container={ref}
                     containerPadding={20} placement="right" on>
                <Popover id="popover-basic">
                    <Popover.Body>
                        {reactions()}
                    </Popover.Body>
                </Popover>
            </Overlay>
            <span>
                {renderedCurrentReactions()}
          </span>
        </div>

    );
}

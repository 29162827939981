import axiosHealthInstance from "../util/axios-health-config";

const useOnlineStatus = () => {
    const checkOnlineStatus = async () => {
        try {
            const online = await axiosHealthInstance.get("/health");
            return online.status >= 200 && online.status < 300;
        } catch (e) {
            return false;
        }
    };

    const handleAction = async () => {
        const status = await checkOnlineStatus();
        return status;
    };


    return {
        checkOnlineStatus: handleAction,
    };
};

export default useOnlineStatus;

import axios from 'axios';
import {getAuthToken} from "./auth";


axios.defaults.baseURL = process.env.REACT_APP_API_AUTH_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.method = 'HEAD';
axios.defaults.mode = 'no-cors';
axios.defaults.headers.common['Authorization'] = getAuthToken();

const axiosHealthInstance = axios.create();
export default axiosHealthInstance;


// RichTextEditor.js
import React, {useState, forwardRef, useImperativeHandle} from 'react';
import {EditorState, convertToRaw} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


const RichTextEditor = forwardRef((props, ref) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useImperativeHandle(ref, () => ({
        resetEditor() {
            setEditorState(EditorState.createEmpty());
        }
    }));

    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState);
        const contentState = newEditorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const htmlContent = draftToHtml(rawContentState);
        const isEmpty = !contentState.hasText();

        if (isEmpty) {
            props.enteredMessage.reset()
        } else {
            props.enteredMessage.inputBlurHandler();

        }
        props.enteredMessage.inputHandler(htmlContent);
    };

    return (
        <Editor
            editorState={editorState}
            toolbar={{
                options: ['inline', 'colorPicker', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'emoji', 'remove', 'history'],
                inline: {inDropdown: false},
                list: {inDropdown: true},
                textAlign: {inDropdown: true},
                link: {inDropdown: true},
                history: {inDropdown: false},
                fontFamily: {
                    options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                },
            }}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={onEditorStateChange}
        />
    );
});

export default RichTextEditor;

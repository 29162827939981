import {useDispatch, useSelector} from "react-redux";
import Message from "./Message";
import React, {useEffect, useRef} from "react";
import {setAutoscroll} from "../../store/message-actions";
import {messageActions} from "../../store/message-slice";

const MessagesList = (props) => {
  const dispatch = useDispatch();
  const isAutoScroll = useSelector(state => state.message.isAutoScroll);
  const displayNewMessageBtn = useSelector(state => state.message.displayNewMessageButton);
  const isNewMessage = useSelector(state => state.message.newMessage);
  const isRoomParticipant = useSelector(state => state.room.roomParticipant);
  const isLoadingMessages = useSelector(state => state.ui.loadingMessages);

  const messagesData = useSelector(state => {
    return state.message.messages
  });
  const chatContainerRef = useRef(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  }
  const scrollToBottomFast = () => {
    messagesEndRef.current.scrollIntoView();
  }

  useEffect(() => {
    if(!isLoadingMessages) {
      scrollToBottomFast();
    }
  }, [isLoadingMessages]);

  useEffect(() => {
    dispatch(setAutoscroll(false));
    scrollToBottomFast();
  },[dispatch]);

  useEffect(() => {

    if(isAutoScroll === false && isNewMessage === true && displayNewMessageBtn === false) {
      dispatch(messageActions.toggleDisplayNewMessageButton())
      // dispatch(messageActions.resetNewMessage())
    }
    if(isAutoScroll === true && isNewMessage === true) {
      dispatch(messageActions.resetNewMessage())
      scrollToBottom();
      if(displayNewMessageBtn === true) {
        dispatch(messageActions.toggleDisplayNewMessageButton())
      }
    }
  }, [isAutoScroll,dispatch, displayNewMessageBtn, isNewMessage]);


  const handleScroll = () => {
    const chatContainer = chatContainerRef.current;
    const isAtBottom = chatContainer.scrollHeight - chatContainer.clientHeight <= chatContainer.scrollTop;
    dispatch(setAutoscroll(isAtBottom));
  };

  const messages = messagesData.map((message, index) => {
    let gapMessageFlag = false;

    if (index > 0) {
      const previousMessage = messagesData[index - 1];
      gapMessageFlag = previousMessage.is_own === message.is_own;
    }

    return <Message
      key={index}
      id={message.id}
      author={message.author}
      message={message.message}
      is_own={message.is_own}
      is_read={message.is_own ? true : message.is_read}
      is_reply={message.is_reply}
      description={message.description}
      type={message.type}
      attachments={message.attachments}
      created_at={message.created_at}
      deleted_at={message.deleted_at}
      sent_sms={message.sent_sms}
      sent_email={message.sent_email}
      firstMessage={index === 0}
      gapMessageFlag={gapMessageFlag}
      is_sent={message.is_sent}
      reactions={message.reactions}
      sms_delivery={message.sms_delivery}
      is_delivered={message.is_delivered}
    />
  });

  const handleNewMessageBtnClick = () => {
    dispatch(messageActions.toggleDisplayNewMessageButton());
    dispatch(setAutoscroll(true));
  }

  return (
    <div className={`${"overflow-auto d-flex flex-column mt-message-container"} ${isRoomParticipant ? 'room-box-container' : 'room-box-container-without-message-box'}`}
         ref={chatContainerRef}
         onScroll={handleScroll}
    >
      {isLoadingMessages ? <div>Is Loading...</div>: messages}
      {displayNewMessageBtn && <button className='btn btn-light' id='scrollingBtn' style={{
        top: 0,
        bottom: 0,
        margin: 'auto',
        position: 'sticky',
        left: 0,
        right: 0,
        inset:'-20px'
      }} onClick={handleNewMessageBtnClick}>New Message</button> }
      <div ref={messagesEndRef} />
    </div>
  );
}

export default MessagesList;

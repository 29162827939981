import React, {useEffect} from 'react';
import {Navigate, Outlet, useLoaderData, useLocation, useSubmit} from 'react-router-dom';
import {getTokenDuration} from '../util/auth';
import messageSound from "../assets/audio/newmessage.wav";


function RootLayout() {
  let location = useLocation();
  const token = useLoaderData();
  const submit = useSubmit();

  useEffect(() => {
    if (!token) {
      return <Navigate to='/auth'/>;
    }
    if (token === 'EXPIRED') {
      // submit(null, {action: '/logout', method: 'post'});
      return <Navigate to='/logout'/>;
    }

    const tokenDuration = getTokenDuration();

    setTimeout(() => {
      return <Navigate to='/auth' replace='/'/>;
    }, tokenDuration);

  }, [token, submit]);

  if (location.pathname === '/') {
    return <Navigate to='/auth' replace='/'/>;
  }
  return (
    <>
      <main>
        <audio src={messageSound} id="new-message-sound" autostart="false" width="0" height="0" />
        <Outlet/>
      </main>
    </>
  );
}

export default RootLayout;

import {configureStore} from "@reduxjs/toolkit";
import uiSlice from "./ui-slice";
import authSlice from "./auth-slice";
import roomSlice from "./room-slice";
import messageSlice from "./message-slice";
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

import storage from 'redux-persist/lib/storage'
import groupSlice from "./group-slice";
import permissionsSlice from "./permissions-slice";

// defaults to localStorage for web

const persistAuthConfig = {
  key: 'auth',
  storage,
}
const persistRoomConfig = {
  key: 'room',
  storage,
}
const persistMessageConfig = {
  key: 'message',
  storage,
}
const persistUIConfig = {
  key: 'ui',
  storage,
}
const persistGroupConfig = {
  key: 'group',
  storage,
}
const persistPermissionsConfig = {
  key: 'permissions',
  storage,
}

const persistedAuthReducer = persistReducer(persistAuthConfig, authSlice.reducer)
const persistedRoomReducer = persistReducer(persistRoomConfig, roomSlice.reducer)
const persistedMessageReducer = persistReducer(persistMessageConfig, messageSlice.reducer)
const persistedUIReducer = persistReducer(persistUIConfig, uiSlice.reducer)
const persistedGroupReducer = persistReducer(persistGroupConfig, groupSlice.reducer)
const persistedPermissionsReducer = persistReducer(persistPermissionsConfig, permissionsSlice.reducer)
export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    room: persistedRoomReducer,
    message: persistedMessageReducer,
    ui: persistedUIReducer,
    group:persistedGroupReducer,
    permissions : persistedPermissionsReducer
  },
  middleware: [thunk]
});
export default store;

import React from 'react';
import { Dropdown } from 'react-bootstrap';
import useCheckPermission from "../../hooks/use-check-permission";
import {useDispatch, useSelector} from "react-redux";
import {uiActions} from "../../store/ui-slice";

const DropdownFirstTitleMenu = () => {
    const dispatch = useDispatch();
    const viewArchivePermission = useCheckPermission('viewArchive');
    const superAdminMode = useCheckPermission('superAdminMode');
    const isActiveSuperAdminMode = useSelector(state => state.ui.activeSuperAdminMode);
    // const viewArchivePermission = true;
    // const Clicking = (e) => {
    //     e.preventDefault();
    // }

    const handleSuperAdminMode = () => {
        dispatch(uiActions.toggleActiveSuperAdminMode());
    }

    return (
        <>
        { (viewArchivePermission || superAdminMode) &&
            <Dropdown>
                <Dropdown.Toggle as={CustomButton} id="dropdown-basic-1" drop="start"/>
                <Dropdown.Menu>
                    {viewArchivePermission && <Dropdown.Item href="#/action-3">View Archive</Dropdown.Item>}
                    {superAdminMode && <Dropdown.Item href="#/action-3" onClick={handleSuperAdminMode}>{isActiveSuperAdminMode ? 'Exit ': ''}Super Admin Mode</Dropdown.Item>}
                </Dropdown.Menu>
            </Dropdown>
        }
        </>
    );
};

const CustomButton = React.forwardRef(({ onClick }, ref) => (
    <button
        ref={ref}
        className="bi bi-three-dots-vertical btn btn-secondary menu-title-box-dropdown shadow-none"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    ></button>
));

export default DropdownFirstTitleMenu;

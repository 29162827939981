import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Container, Row, Col, Form, Button, Alert, Card} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {login} from '../store/auth-actions';
import {authActions} from "../store/auth-slice";
import {uiActions} from "../store/ui-slice";
import {roomActions} from "../store/room-slice";
import {messageActions} from "../store/message-slice";
import {groupActions} from "../store/group-slice";
import {permissionsActions} from "../store/permissions-slice";
import useOnlineStatus from "../hooks/use-online-status";

const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
const LoginPage = () => {
    const {checkOnlineStatus} = useOnlineStatus();
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const loginFail = useSelector(state => state.auth.loginFail);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setShowError(loginFail);
  }, [loginFail]);
  if(token === null) {
    dispatch(uiActions.reset());
    dispatch(roomActions.reset());
    dispatch(messageActions.reset());
    dispatch(groupActions.resetGroup());
    dispatch(permissionsActions.reset());
  }


  useEffect(() => {
    if (token !== null) {
      return navigate('/chat');
    }
  }, [token, navigate, dispatch]);


  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isEmail(email)) {
      setShowError(true);
      return;
    }
    if(password.length < 6 ) {
      setShowError(true);
      return;
    }
      checkOnlineStatus().then((resp) => {
          dispatch(uiActions.toggleIsOnline(resp))
          if(resp) {
              dispatch(login(email, password));
          }
      })
  };

  const handleChangeEmail = (e) => {
      setEmail(e.target.value.trim())
  }
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  }

  const handleCloseAlert = () => {
    setShowError(false);
    dispatch(authActions.reset());
  }

  return (


      <Container>
          <Row className="justify-content-md-center mt-5">
              <Col md={6}>
                  <Card>
                      <Card.Header>
                          <h1>Login</h1>
                      </Card.Header>

                      <Card.Body>
                          <Form onSubmit={handleSubmit}>
                              <Form.Group controlId="formBasicEmail" className="mb-2">
                                  <Form.Label className="m-0">Email address</Form.Label>
                                  <Form.Control
                                      type="text"
                                      placeholder="Enter email"
                                      name="email"
                                      value={email}
                                      onChange={handleChangeEmail}
                                  />
                              </Form.Group>

                              <Form.Group controlId="formBasicPassword">
                                  <Form.Label className="m-0">Password</Form.Label>
                                  <Form.Control
                                      type="password"
                                      placeholder="Password"
                                      name="password"
                                      value={password}
                                      onChange={handleChangePassword}
                                  />
                              </Form.Group>

                              <Row className='mt-4'>
                                  <Col md={12}>
                                      <Button variant="primary" type="submit">
                                          Submit
                                      </Button>
                                  </Col>
                              </Row>
                          </Form>
                      </Card.Body>
                  </Card>
                  {showError && (
                      <Alert variant="danger" className='mt-2'>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                  onClick={handleCloseAlert}
                          >
                              <span aria-hidden="true">&times;</span>
                          </button>
                          <Alert.Heading>Invalid credentials</Alert.Heading>
                          <p>Please try again.</p>
                      </Alert>
                  )}
              </Col>
          </Row>
      </Container>


  );
};

export default LoginPage;

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    participants: [],
    groupName: '',
    nbParticipants: 0,
    error: null,
    deletedParticipant: {id: 0, name:'',roomUuid:'' },
};
const groupSlice = createSlice({
    name: 'group',
    initialState: initialState,
    reducers: {
        addNewParticipant: (state, participant) => {
            return {
                ...state,
                participants: [...state.participants, participant.payload],
                nbParticipants: state.nbParticipants + 1
            }
        },
        removeNewParticipant: (state, uuid) => {
            const participants = state.participants.filter(el => el.uuid !== uuid.payload);
            return {
                ...state,
                participants: participants,
                nbParticipants: participants.length
            }

        },
        setGroupName: (state, groupName) => {
            return {
                ...state,
                groupName: groupName.payload
            }
        },
        setDeletedParticipant: (state, user) => {
            return {
                ...state,
                deletedParticipant: {
                    id: user.payload.id,
                    name: user.payload.name,
                    roomUuid: user.payload.roomUuid
                }
            }
        },
        resetDeletedParticipant: (state) => {
            return {
                ...state,
                deletedParticipant: {id: 0, name:'',roomUuid:'' }
            }
        },
        resetGroup: (state) => {
            return initialState;
        }

    },
});

export const groupActions = groupSlice.actions;

export default groupSlice;

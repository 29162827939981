import React, {Suspense, useEffect} from "react";
import {Row} from "react-bootstrap";
import RoomMenu from "../components/UI/RoomMenu";
import MessageContainer from "../components/UI/MessageContainer";
import {useDispatch, useSelector} from "react-redux";
import {fetchRoomData} from "../store/room-actions";
import {fetchUserInfo} from "../store/auth-actions";
import echo from "../util/pusher-config";
import Modals from "../components/UI/Modals";
import {Navigate} from "react-router-dom";
import {ToastContainer} from "react-toastify";

function HomePage() {
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const adminMode = useSelector(state => state.ui.activeSuperAdminMode);



    const userId = useSelector(state => {
        if (state.auth.user !== null) {
            return state.auth.user.id;
        }

        return null;
    });
    if(token === null) {
        localStorage.removeItem('token');
        return <Navigate to={'/auth'} replace={true}/>
    }

    if (localStorage.getItem('token')) {

        echo.private(`users.${userId}`)
            .listen('.new_message', (message) => {
                console.log('received new message')
            });
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        dispatch(fetchRoomData(adminMode));
        dispatch(fetchUserInfo());
    }, [dispatch, adminMode])

    return (
        <>
            <Modals/>
            <Row className='m-0'>
                <Suspense fallback={<div>Rooms are loading...</div>}>
                    <RoomMenu/>
                </Suspense>
                <MessageContainer/>
                <ToastContainer/>
            </Row>

        </>
    );
}

export default HomePage;

import React from 'react';
import {Dropdown} from 'react-bootstrap';
import useCheckPermission from "../../hooks/use-check-permission";
import {useDispatch, useSelector} from "react-redux";
import {deleteMessage, reportMessage} from "../../store/message-actions";
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const DropdownMessageMenu = (props) => {
    const role = useSelector(state => state.auth.user.roles[0].name);
    const dispatch = useDispatch();
    let deleteMessagePermission = false;
    const reportMessagePermission = useCheckPermission('reportMessage');
    const deleteAllMessagesPermission = useCheckPermission('deleteAllMessages');
    const deleteOwnMessagePermission = useCheckPermission('deleteOwnMessage');

    if(role === 'superadmin' && deleteAllMessagesPermission) {
        deleteMessagePermission = true;
    } else
        if(deleteOwnMessagePermission && props.is_own) {
            deleteMessagePermission = true;
        }

    const Clicking = (e) => {
        e.preventDefault();
        dispatch(reportMessage(props.message_id));
        toast.info("Message Reported!", {
            position: "top-center"
        });
    }
    const handleDeleteMessage = () => {
        dispatch(deleteMessage(props.message_id));
        toast.success("Message Deleted!", {
            position: "top-center"
        });
    }
    return (
        <>
            {
                (reportMessagePermission || deleteMessagePermission) &&
                <Dropdown>
                    <Dropdown.Toggle as={CustomButton} id="dropdown-basic" drop="start"/>
                    <Dropdown.Menu>
                        {reportMessagePermission &&
                            <Dropdown.Item href="#/action-1" onClick={Clicking}>Report Message</Dropdown.Item>}
                        {deleteMessagePermission && <Dropdown.Item href="#/action-2" onClick={handleDeleteMessage}>Delete Message</Dropdown.Item>}
                    </Dropdown.Menu>
                </Dropdown>
            }
        </>
    );
};

const CustomButton = React.forwardRef(({onClick}, ref) => (
    <button
        ref={ref}
        className="bi bi-three-dots-vertical btn p-0 align-top shadow-none"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
    </button>
));

export default DropdownMessageMenu;

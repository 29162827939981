import {lazy, Suspense} from "react";
import {useSelector} from "react-redux";

const PersonModal = lazy(() => import("../../components/Modals/PersonModal"));
const AgreementModal = lazy(() => import("../../components/Modals/AgreementModal"));
const ContactConfirmationModal = lazy(() => import("../../components/Modals/ContactConfirmationModal"));
const NewThreadTypeModal = lazy(() => import("../../components/Modals/NewThreadTypeModal"));
const GroupModal = lazy(() => import("../../components/Modals/GroupModal"));
const AddGroupMemberModal = lazy(() => import("../../components/Modals/AddGroupMemberModal"));
const CreateGroupModal = lazy(() => import("../../components/Modals/CreateGroupModal"));
const EditGroupModal = lazy(() => import("../../components/Modals/EditGroupModal"));
const PreDefinedGroupModal = lazy(() => import("../../components/Modals/PreDefinedGroupModal"));
const EditGroupAddToGroupMembersModal = lazy(() => import("../../components/Modals/EditGroupAddToGroupMembersModal"));
const ViewGroupModal = lazy(() => import("../../components/Modals/ViewGroupModal"));
const SendAttachmentModal = lazy(() => import("../../components/Modals/SendAttachmentModal"));

const Modals  = () => {
    const agreementProvided = useSelector(state => state.auth.user.agreement);
    const contactConfirmation = useSelector(state => state.auth.user.contact_confirmation);

    return (
            <Suspense fallback={<div>Loading...</div>}>
                <NewThreadTypeModal/>
                <PersonModal/>
                <GroupModal/>
                <PreDefinedGroupModal/>
                <AddGroupMemberModal/>
                <CreateGroupModal/>
                <EditGroupModal/>
                <EditGroupAddToGroupMembersModal/>
                <ViewGroupModal/>
                <SendAttachmentModal/>

                {!agreementProvided && <AgreementModal/>}
                {!contactConfirmation && <ContactConfirmationModal/>}
            </Suspense>
    );
}
export default Modals;

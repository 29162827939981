// useInput.js
import { useState } from "react";

const useInput = (initialValue, validateValue) => {
    const [enteredValue, setEnteredValue] = useState(initialValue);
    const [inputIsTouched, setInputIsTouched] = useState(false);

    const valueIsValid = validateValue(enteredValue);
    const inputIsValidAndTouched = valueIsValid && inputIsTouched;
    const inputHasError = !valueIsValid && inputIsTouched;
    const inputClasses = inputHasError ? 'form-control invalid' : 'form-control';

    const inputHandler = (value) => {
        setEnteredValue(value);
    };

    const inputBlurHandler = () => {
        setInputIsTouched(true);
    };

    const reset = () => {
        setEnteredValue('');
        setInputIsTouched(false);
    };

    return {
        enteredValue,
        inputIsValidAndTouched,
        inputIsTouched,
        inputHasError,
        inputClasses,
        inputHandler,
        inputBlurHandler,
        reset
    };
};

export default useInput;

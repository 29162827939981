import {redirect} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

import LoginPage from "../components/LoginPage";
import {useEffect} from "react";
import {loginByID} from "../store/auth-actions";


function AuthenticationPage() {
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);

  useEffect(() => {
    const externalElement = document.getElementById('personID');
    if (externalElement) {
      const externalValue = externalElement.value;
      if(!isNaN(parseInt(externalValue)))
      {
        dispatch(loginByID(parseInt(externalValue)));
      }

    }
  }, [dispatch]);
  if (token !== null) {
    redirect('/chat');
  }
  return <LoginPage/>;
}

export default AuthenticationPage;

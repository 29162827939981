import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    rooms: [],
    activeRoomIndex: null,
    activeRoomName: '',
    activeRoomType: '',
    roomParticipant: true,
    kidsCanMessage: true,
    error: null,
};
const roomSlice = createSlice({
    name: 'room',
    initialState: initialState,
    reducers: {
        setRoomList: (state, action) => {
            return {
                ...state,
                rooms: action.payload,
            }
        },
        addNewRoom: (state, room) => {
            const rooms = [room.payload, ...state.rooms];
            return {
                ...state,
                rooms: rooms,
                activeRoomIndex: room.payload.uuid,
                activeRoomName: room.payload.name,
                activeRoomType: room.payload.type,
            }
        },
        addNewPersonalRoom: (state, room) => {

            const roomIndex = state.rooms.findIndex(roomElem => roomElem.uuid === room.payload.uuid);
            if(roomIndex === -1) {
                return {
                    ...state,
                    rooms: [room.payload,...state.rooms],
                    activeRoomIndex: room.payload.uuid,
                    activeRoomName: room.payload.name,
                    activeRoomType: room.payload.type,
                }
            } else {
                return {
                    ...state,
                    activeRoomIndex: room.payload.uuid,
                    activeRoomName: room.payload.name,
                    activeRoomType: room.payload.type,
                }
            }
        },
        removeRoom: (state, room) => {
            const rooms = state.rooms.filter(roomElem => roomElem.uuid !== room.payload);
                return {
                    ...state,
                    rooms: rooms,
                    activeRoomIndex: null,
                    activeRoomName: '',
                    activeRoomType: '',
                }

        },
        addNewParticipantsToRoom: (state, room) => {

            const roomIndex = state.rooms.findIndex(roomElem => roomElem.uuid === room.payload.uuid);
            if(roomIndex === -1) {
                return {
                    ...state,
                    rooms: [...state.rooms, room.payload],
                    activeRoomIndex: room.payload.uuid,
                    activeRoomName: room.payload.name,
                    activeRoomType: room.payload.type,
                }
            } else {
                let rooms = [...state.rooms];
                rooms[roomIndex] = room.payload;
                return {
                    ...state,
                    rooms: rooms,
                    activeRoomIndex: room.payload.uuid,
                    activeRoomName: room.payload.name,
                    activeRoomType: room.payload.type,
                }
            }
        },
        removeParticipantFromRoom: (state, data) => {
            const roomIndex = state.rooms.findIndex(roomElem => roomElem.uuid === data.payload.roomUuid);
            if(roomIndex === -1) {
                return {
                    ...state,
                }
            } else {
                let rooms = [...state.rooms];
                const participants = rooms[roomIndex].last_participant_users.filter(el => el.id !== data.payload.userId);
                rooms[roomIndex] = {
                    ...rooms[roomIndex],
                    last_participant_users:participants,
                    participant_users_count: rooms[roomIndex].participant_users_count - 1
                };
                return {
                    ...state,
                    rooms: rooms,
                }
            }
        },
        roomFail: (state, action) => {
            return initialState;
        },
        reset: (state) => {
            return initialState;
        },
        setActiveRoom: (state, room) => {
            if(room.payload === null){
                return;
            }

            localStorage.setItem('last_active_room', JSON.stringify({
                ...room.payload,
                activeRoomIndex: room.payload.uuid,
                activeRoomName: room.payload.name,
                activeRoomType: room.payload.type,
                roomParticipant: room.payload.isRoomParticipant,
                room_participant: room.payload.isRoomParticipant,
                kidsCanMessage: room.payload.kidsCanMessage,
            }))

            return {
                ...state,
                activeRoomIndex: room.payload.uuid,
                activeRoomName: room.payload.name,
                activeRoomType: room.payload.type,
                roomParticipant: room.payload.isRoomParticipant,
                kidsCanMessage: room.payload.kidsCanMessage,


            }

        },
        updateRoomReadMessages:(state) => {
            const activeRoomIndex = state.activeRoomIndex;
            const roomIndex = state.rooms.findIndex(room => room.uuid === activeRoomIndex);
            let rooms = [...state.rooms];

            rooms[roomIndex] = {
                ...rooms[roomIndex],
                unread_messages: (rooms[roomIndex].unread_messages-1)
            }
            return {
                ...state,
                rooms: rooms,
            }
        },
        updateRoomLastMessageDateTime:(state, roomData) => {
            const activeRoomIndex = roomData.payload.activeRoomIndex;
            const roomIndex = state.rooms.findIndex(room => room.uuid === activeRoomIndex);
            let rooms = [...state.rooms];

            rooms[roomIndex] = {
                ...rooms[roomIndex],
                updated_at: roomData.payload.updatedAt
            }
            return {
                ...state,
                rooms: rooms,
            }
        }

    },
});

export const roomActions = roomSlice.actions;

export default roomSlice;

import {Col} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import DropdownTitleMenu from "../UI/DropdownTitleMenu";
import {uiActions} from "../../store/ui-slice";


const MessageContainerTitle = () => {
    const dispatch = useDispatch();

    let room = useSelector(state => state.room.activeRoomName);
    const roomIndex = useSelector(state => state.room.activeRoomIndex);
    const roomType = useSelector(state => state.room.activeRoomType);
    const activeSuperAdminMode = useSelector(state => state.ui.activeSuperAdminMode);
    const [activeRoom] = useSelector(state => state.room.rooms.filter(el => el.uuid === roomIndex));

    if(activeSuperAdminMode && roomType === 'personal_chat') {
        room = activeRoom.last_participant_users.map( user => user.name).join(' & ');
    }



    const handleDisplayContainer = () => {
        dispatch(uiActions.toggleActiveRoomContainer());
    }

    return (
        <Col
            className="col-12 col-sm-12 col-md-8 col-lg-9 flex-row d-flex room-box-title justify-content-between align-items-center">
            <button className="btn bi bi-chevron-left shadow-none d-md-none d-lg-none"
                    onClick={handleDisplayContainer}></button>
            <div className="p-2 room-title">
                {room === undefined ? '' : room}
            </div>
            <DropdownTitleMenu/>
        </Col>
    );
}

export default MessageContainerTitle;

import {Col, Figure, Row} from "react-bootstrap";
import "yet-another-react-lightbox/styles.css";
import React from "react";
import Lightbox from "yet-another-react-lightbox";

export function RenderImage(props) {
  const [open, setOpen] = React.useState(false)
  const attachment = props.attachment;

  return (
    <>
      <Figure className="attachment-image" key={attachment.id}>
        <Figure.Image
          alt="171x180"
          onClick={() => setOpen(true)}
          src={attachment.url}
        />
        <Figure.Caption>
          {attachment.name}
        </Figure.Caption>
      </Figure>
      <Lightbox
        open={open}
        render={{
          buttonPrev: () => null,
          buttonNext: () => null,
        }}
        close={() => setOpen(false)}
        slides={[
          {src: attachment.url},
        ]}
      />
    </>
  );
}


export function RenderDocument(props) {
  const attachment = props.attachment;
  return (
    <a href={attachment.url} key={attachment.id} download>
      <Row className="attachment-document">
        <Col className="col-12">
          <i className="bi bi-files mr-2"></i> {attachment.name}
        </Col>
      </Row>
    </a>
  )
}

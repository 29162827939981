const LastParticipants = (props) => {
  let zindex=10;
  let index = 0;
  let avatars;
  let badge;
  const participants = props.participant_users_count - props.last_participant_users.length;
  const participantsNumber =  participants > 0 ? '+'+participants : '';
  if(props.last_participant_users.length === 2) {
    avatars = <img key={props.last_participant_users[0].id + Math.random()}
                         src={props.last_participant_users[0].avatar_url}
                         className="bg-info participant-user-avatar rounded-circle"
                         style={{zIndex: zindex--, left: (15 * index++) + 'px'}}
                          alt={'avatar'}
                         />;
    badge = props.tag.length > 0 ? <span className="badge bg-secondary badge-room-adjustments">{props.tag}</span> : '';
  } else {
    avatars = props.last_participant_users.map((participant) => {
      return <img key={participant.id + Math.random()}
                  src={participant.avatar_url}
                  className="bg-info participant-user-avatar rounded-circle"
                  style={{zIndex: zindex--, left: (15 * index++) + 'px'}}
                  alt={'avatar'}
      />

    });
    badge = <span className="text-muted room-participant-number" style={{
      left: ((15 * index++)) + 'px'
    }}
    >{participantsNumber}</span>
  }

  return (
    <div className="col-6 d-flex align-items-center" style={{height:30 + 'px'}}>
      {avatars} {badge}
    </div>
  )
}

export default LastParticipants;

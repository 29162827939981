import {useNavigate, useRouteError} from "react-router-dom";
import {useEffect} from "react";

function ErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate();



  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      navigate('/logout'); // Replace '/new-route' with the desired destination path
    }, 3000);

    return () => {
      clearTimeout(redirectTimeout);
    };
  }, []);

  let title = 'Error title';
  let message = 'An error occurred!';
  if (error.status === 500) {
    title = 'Critical error';
    // message = JSON.parse(error.data).message;
    message = error.data.message;
  }
  if (error.status === 404) {
    title = 'Not found!';
  }

  return (
    <>
      <h1>{title}</h1>
      <p>{message}</p>
    </>

  )
}

export default ErrorPage;

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  messages: [],
  paginatorData: {},
  error: null,
  isAutoScroll: false,
  newMessage: false,
  displayNewMessageButton: false,
  sendingAttachmentProgress: 0,
};
const messageSlice = createSlice({
  name: 'message',
  initialState: initialState,
  reducers: {
    messageSuccess: (state, action) => {
      return {
        ...state,
        messages: action.payload
      }
    },
    addMessage: (state, action) => {
      return {
        ...state,
        messages: [...state.messages, action.payload],
        newMessage: true
      }
    },
    updateMessageReactions: (state, action) => {
    const payload = action.payload
    const messageIndex = state.messages.findIndex(message => message.id === payload.message_id);

    if (messageIndex === -1) {
        return {...state};
    }
        let messages = [...state.messages];
        messages[messageIndex] = {
            ...messages[messageIndex],
            reactions: payload.reactions
        }
        return {
            ...state,
            messages: messages,
        }
    },
    newMessageReaction: (state, action) => {
      const payload = action.payload
      const messageIndex = state.messages.findIndex(message => message.id === payload.message_id);

      if (messageIndex === -1) {
        return {...state};
      }

      let messages = [...state.messages];

      const reactions = {...messages[messageIndex].reactions};

      if (reactions.hasOwnProperty(payload.reaction_markdown)) {
        reactions[payload.reaction_markdown]++;
      } else {
        reactions[payload.reaction_markdown] = 1;
      }

      messages[messageIndex] = {
        ...messages[messageIndex],
        reactions: reactions
      }

      return {
        ...state,
        messages: messages,
      }
    },
    unsendMessageReaction: (state, action) => {
      const payload = action.payload
      const messageIndex = state.messages.findIndex(message => message.id === payload.message_id);

      if (messageIndex === -1) {
        return {...state};
      }

      let messages = [...state.messages];

      const reactions = {...messages[messageIndex].reactions};

      if (reactions[payload.reaction_markdown] > 1) {
        reactions[payload.reaction_markdown]--;
      } else {
        delete reactions[payload.reaction_markdown];
      }

      messages[messageIndex] = {
        ...messages[messageIndex],
        reactions: reactions
      }

      return {
        ...state,
        messages: messages,
      }
    },
    updateSmsDelivery: (state, action) => {
      const delivery = action.payload;
      const messageId = action.payload.message_id;

      const messageIndex = state.messages.findIndex(message => message.id === messageId);
      if(messageIndex === -1){
        return {...state};
      }

      const messages = [...state.messages];

      messages[messageIndex] = {
        ...messages[messageIndex],
        sms_delivery: delivery
      }

      return {
        ...state,
        messages: messages,
      }
    },
    messageDelivered: (state, action) => {
      const messageIndex = state.messages.findIndex(message => message.id === action.payload.id);
      if(messageIndex === -1){
        return {...state}
      }

      let messages = [...state.messages]
      messages[messageIndex] = {
        ...action.payload
      }

      return {
        ...state,
        messages: messages,
      }
    },
    failedSending: (state, action) => {
      const messageIndex = state.messages.findIndex(message => message.id === action.payload);
      let messages = [...state.messages];
      messages[messageIndex] = {
        ...messages[messageIndex],
        is_sent: false
      }

      return {
        ...state,
        messages: messages,
      }
    },
    sent: (state, action) => {
      const messageIndex = state.messages.findIndex(message => message.random_id === action.payload.random_id);

      let messages = [...state.messages];

      messages[messageIndex] = {
        ...action.payload.message,
        is_sent: true
      }

      return {
        ...state,
        messages: messages,
      }
    },
    resetNewMessage: (state) => {
      return {
        ...state,
        newMessage: false
      }
    },
    setPaginator: (state, action) => {
      return {
        ...state,
        paginatorData: action.payload
      }
    },
    setAutoScroll: (state, action) => {
      return {
        ...state,
        isAutoScroll: action.payload,
      }
    },
    readMessage: (state, action) => {

      const messageIndex = state.messages.findIndex(message => message.id === action.payload);
      let messages = [...state.messages];
      messages[messageIndex] = {
        ...messages[messageIndex],
        is_read: true
      }

      return {
        ...state,
        messages: messages,

      }
    },
    setSendAttachmentProgress: (state, action) => {
      return {
        ...state,
        sendingAttachmentProgress: action.payload
      }
    },
    deleteMessage: (state, action) => {

      return {
        ...state,
        messages: state.messages.filter(message => message.id !== action.payload),
      }
    },
    toggleDisplayNewMessageButton: (state) => {
      return {
        ...state,
        displayNewMessageButton: !state.displayNewMessageButton
      }
    },
    messageFail: (state, action) => {
      return initialState;
    },
    reset: (state, action) => {
      return initialState;
    },
  },
});

export const messageActions = messageSlice.actions;

export default messageSlice;

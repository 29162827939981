import {createBrowserRouter, RouterProvider} from "react-router-dom";


import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import RootLayout from "./pages/RootLayout";
import ErrorPage from "./pages/Error";
import HomePage from "./pages/Home";
import AuthenticationPage from "./pages/Authentification";
import {checkAuthLoader, checkIfLoggedLoader} from "./util/auth";
import Logout, {action as logoutAction} from './pages/Logout';
import React, {useEffect, useState} from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import useOnlineStatus from './hooks/use-online-status';
import Offline from "./pages/Offline";
import {useDispatch, useSelector} from "react-redux";
import {uiActions} from "./store/ui-slice";



export let historyNav;
historyNav = {
    navigate: () => undefined,
};




const router = createBrowserRouter([
        {
            path: '/',
            element: <RootLayout/>,
            errorElement: <ErrorPage/>,
            id: 'root',
            loader: checkAuthLoader,
            children: [
                {
                    path: '/chat',
                    element: <RootLayout/>,
                    loader: checkAuthLoader,
                    children: [
                        {
                            index: true,
                            element: <HomePage/>,
                            loader: checkAuthLoader
                        }
                    ],
                },
                {
                    path: 'logout',
                    element: <Logout/>,
                    action: logoutAction
                },
            ]
        },
        {
            path: 'auth',
            element: <AuthenticationPage/>,
            loader: checkIfLoggedLoader
        },
        {
            path: 'offline',
            element: <Offline/>,
        },
    ],
     // {basename: "/secure-messaging"}
);

function App() {
     const { checkOnlineStatus} = useOnlineStatus();
     const dispatch = useDispatch();

    const isOnline = useSelector(state => state.ui.isOnline);
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (!isOnline) {
                checkOnlineStatus().then((resp) => {
                    dispatch(uiActions.toggleIsOnline(resp))
                })
            }
        }, 5000);
        return () => clearInterval(intervalId);
    }, [checkOnlineStatus, dispatch, isOnline]);


    const [queryClient] = useState(() => new QueryClient());
    return (
        <>
            { isOnline && <QueryClientProvider client={queryClient}>
                <RouterProvider router={router}/>
            </QueryClientProvider>
            }
            {
                !isOnline && <Offline/>
            }
        </>
    );
}

export default App;

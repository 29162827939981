import {createSlice} from '@reduxjs/toolkit';
import {historyNav} from "../App";

const initialState = {
    token: null,
    user: {
        id: null,
        uuid: '',
        name: '',
        email: '',
        phone: '',
        avatar_url: '',
        roles:[[{name: ''}]],
        system_id: null,
        is_underaged: false,
        created_at: null,
        agreement: true,
        contact_confirmation:false,
    },
    loginFail: false,
    error: null,
};
const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        loginSuccess: (state, action) => {
            if(action.payload.user.is_underaged) {
                window.location.href = 'https://www.bluedevils.org';
                localStorage.removeItem('token');
                localStorage.clear();
                return initialState;
            }
            return {
                token:action.payload.token,
                user: action.payload.user,
                error: null
            }
        },
        updateUser: (state, action) => {
            return {
                token:action.payload.token,
                user: action.payload.user,
                error: null
            }
        },
        logout: (state, action) => {
            localStorage.removeItem('token');
            localStorage.clear();
            historyNav.navigate('/auth');
            return initialState;
        },
        reset : (state, action) => {
            return initialState;
        },
        loginFail: (state, action) => {
            return {
                ...state,
                loginFail: true,
            };
        }
    },
});

export const authActions = authSlice.actions;

export default authSlice;

import {createSlice} from "@reduxjs/toolkit";

const initialPermissionsState = {
    "superadmin": {
        createMessage: true,
        hideMessageGroups: true,
        manageOwnGroups: true,
        viewGroupMembers: true,
        reactToMessages: true,
        readMessage: true,
        replyToMessages: true,
        showHiddenMessages: true,
        viewRooms: true,
        superAdminMode: true,
        sendMessageAsSMS: true,
        sendMessageAsEmail: true,
        removeChat: false,
        exitChat: true,
        silenceNotifications: false,
        viewArchive: false,
        reportMessage: true,
        deleteAllMessages: true,
        deleteOwnMessage: true,
        sendAttachment: true,
    },
    "admin": {
        createMessage: true,
        hideMessageGroups: true,
        manageOwnGroups: true,
        viewGroupMembers: true,
        reactToMessages: true,
        readMessage: true,
        replyToMessages: true,
        showHiddenMessages: true,
        viewRooms: true,
        superAdminMode: false,
        sendMessageAsSMS: true,
        sendMessageAsEmail: true,
        removeChat: false,
        exitChat: true,
        silenceNotifications: false,
        viewArchive: false,
        reportMessage: true,
        deleteAllMessages: false,
        deleteOwnMessage: true,
        sendAttachment: true,
    },
    "staff": {
        createMessage: true,
        hideMessageGroups: false,
        manageOwnGroups: true,
        viewGroupMembers: true,
        reactToMessages: true,
        readMessage: true,
        replyToMessages: true,
        showHiddenMessages: false,
        viewRooms: true,
        superAdminMode: false,
        sendMessageAsSMS: true,
        sendMessageAsEmail: true,
        removeChat: false,
        exitChat: true,
        silenceNotifications: false,
        viewArchive: false,
        reportMessage: false,
        deleteAllMessages: false,
        deleteOwnMessage: true,
        sendAttachment: true,
    },
    "manager": {
        createMessage: true,
        hideMessageGroups: false,
        manageOwnGroups: true,
        viewGroupMembers: true,
        reactToMessages: true,
        readMessage: true,
        replyToMessages: true,
        showHiddenMessages: false,
        viewRooms: true,
        superAdminMode: false,
        sendMessageAsSMS: true,
        sendMessageAsEmail: true,
        removeChat: false,
        exitChat: true,
        silenceNotifications: false,
        viewArchive: false,
        reportMessage: false,
        deleteAllMessages: false,
        deleteOwnMessage: true,
        sendAttachment: true,
    },
    "band-member": {
        createMessage: true,
        hideMessageGroups: false,
        manageOwnGroups: false,
        viewGroupMembers: false,
        reactToMessages: true,
        readMessage: true,
        replyToMessages: false,
        showHiddenMessages: false,
        viewRooms: true,
        superAdminMode: false,
        sendMessageAsSMS: false,
        sendMessageAsEmail: true,
        removeChat: false,
        exitChat: true,
        silenceNotifications: false,
        viewArchive: false,
        reportMessage: false,
        deleteAllMessages: false,
        deleteOwnMessage: true,
        sendAttachment: false,
    },
    "fan": {
        createMessage: false,
        hideMessageGroups: false,
        manageOwnGroups: false,
        viewGroupMembers: false,
        reactToMessages: true,
        readMessage: true,
        replyToMessages: false,
        showHiddenMessages: false,
        viewRooms: true,
        superAdminMode: false,
        sendMessageAsSMS: false,
        sendMessageAsEmail: false,
        removeChat: false,
        exitChat: true,
        silenceNotifications: false,
        viewArchive: false,
        reportMessage: false,
        deleteAllMessages: false,
        deleteOwnMessage: true,
        sendAttachment: false,

    }
};

const permissionsSlice = createSlice({
    name: 'permissions', initialState: initialPermissionsState,
    reducers: {
        reset: (state) => {
            return initialPermissionsState
        },
    }
});

export const permissionsActions = permissionsSlice.actions;
export default permissionsSlice;

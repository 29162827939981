export function dateFormat(date) {
  const created_at = new Date(date);
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: "America/Los_Angeles",
  };

  return created_at.toLocaleDateString("en-US", options);

}

import {useSelector} from "react-redux";
import Room from "./Room";
import {Suspense} from "react";


const RoomsList = () => {
  const isLoadingRooms = useSelector(state => state.ui.loadingRooms);
  const activeSuperAdminMode = useSelector(state => state.ui.activeSuperAdminMode);


  const {rooms:roomsData, activeRoomIndex:activeRoom} = useSelector(state => {
    return state.room
  });

  const rooms = roomsData.map((room) => {
    let roomTitle = room.name;

    if(activeSuperAdminMode && room.type === 'personal_chat') {
      roomTitle = room.last_participant_users.map( user => user.name.split(' ')[0]).join(' & ');
    }

    // let canMessageKids = true;

    // if(room.type === 'personal_chat'){
    //   room.last_participant_users.forEach(function(participant){
    //     if(participant.roles[0].name !== 'band-member'){
    //       canMessageKids =  false;
    //     }
    //   })
    // }

    return <Room
        key={room.id}
        id={room.id}
        uuid={room.uuid}
        name={room.name}
        roomTitle={roomTitle}
        tag={room.tag !== 'tag' ? room.tag : ''}
        type={room.type}
        isActive={room.uuid === activeRoom}
        isRoomParticipant={room.room_participant}
        description={room.description}
        kidsCanMessage={room.kidsCanMessage}
        updated_at={room.updated_at}
        created_at={room.created_at}
        last_participant_users={room.last_participant_users}
        participant_users_count={room.participant_users_count}
        unread_messages={room.unread_messages}
    />
  });
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        {isLoadingRooms ? <div>Loading...</div>:rooms}
      </Suspense>
    </>
  );
}
export default RoomsList;

import {permissionsCheck} from "../util/permissions-check";
import {useSelector} from "react-redux";

const useCheckPermission = (validatePermission) => {
    const role = useSelector(state => state.auth.user.roles[0].name);
    const permissions = useSelector(state => state.permissions[role]);
    return permissionsCheck(permissions, validatePermission);
}

export default useCheckPermission;
